$(document).ready(function () {
    function toggleNavigation() {
        if ($('body').hasClass('display-nav')) {
            // Close Nav
            $('body').removeClass('display-nav');
        } else {
            // Open Nav
            $('body').addClass('display-nav');
        }
    }

    //HAMBURGUERS
    var forEach = function (t, o, r) {
        if ("[object Object]" === Object.prototype.toString.call(t))
            for (var c in t) Object.prototype.hasOwnProperty.call(t, c) && o.call(r, t[c], c, t);
        else
            for (var e = 0, l = t.length; l > e; e++) o.call(r, t[e], e, t)
    };
    var hamburgers = document.querySelectorAll(".hamburger");
    if (hamburgers.length > 0) {
        forEach(hamburgers, function (hamburger) {
            hamburger.addEventListener("click", function () {
                this.classList.toggle("is-active");
            }, false);
        });
    }
    $(function () {
        $('.hamburger').click(function () {
            toggleNavigation();
        });
    });

    $('.expand-produtos').click(function () {
        $('.submenu-expand').toggleClass('aberto-submenu');
        $('i').toggleClass('fa fa-angle-down fa fa-angle-up')
    });




    $(".clickRouteJS").click(function () {
        $(this).parent().toggleClass('active').find('.categorias-sub-menu').slideToggle('fast');
        $(this).parent().toggleClass('active').find('.trigger-accordeon').toggleClass('fa-open-show');
    });

    /*
     * LOGIN LOJISTA
     * */
    var formLojista = $('#login_lojista');
    var btnLojista = $('#submit_lojista');

    btnLojista.click(function (e) {
        e.preventDefault();

        $.ajax({
            dataType: 'json',
            type: 'POST',
            url: rootPath + 'header_ajax_request?act=loginLojista',
            data: formLojista.serialize(),
            beforeSend: function () {
                $('.cml_error_class_cads').removeClass('cml_error_class_cads');
            },
            success: function (result) {
                if (result.status) {
                    location.href = result.url;
                } else {
                    $('#cadastro_cnpj').addClass('cml_error_class_cads');
                    $('#cadastro_password').addClass('cml_error_class_cads');
                }
            }
        });
    });

    /*
     * RECUPERAR SENHA
     */

    var formEsqueci = $('#esqueci_senha_form');
    var elmCnpj = $('#esquecisenha_cnpj');
    var btnEsqueci = $('#esqueci_senha_submit');

    btnEsqueci.click(function (e) {
        e.preventDefault();

        $.ajax({
            dataType: 'json',
            type: 'POST',
            url: rootPath + 'header_ajax_request?act=esqueciSenha',
            data: formEsqueci.serialize(),
            beforeSend: function () {
                $('.cml_error_class_cads').removeClass('cml_error_class_cads');
            },
            success: function (result) {
                if (result.status) {
                    alert('Senha provisória enviada ao seu e-mail.');
                } else {
                    alert('Nenhum cadastro encontrado com este CNPJ.');
                    //elmCnpj.addClass('cml_error_class_cads');
                }
            }
        });
    });

    //Mascaras Padrão
    $.each($("[type='mask']"), function (index, value) {
        var masked = $(this);
        $(masked).mask(masked.attr('mask'));
    });

    $('.go-to').click(function (e) {
        e.preventDefault();
        tag = $(this);

        value = tag.attr('sub');

        if ($('.go-to-' + value).length) {
            $('html, body').animate({
                scrollTop: $('.go-to-' + value).offset().top
            }, 400);
        } else {
            window.location.href = tag.attr('href');
        }
    });

    $(window).resize(function () {
        setTimeout(function () {
            $.fn.matchHeight._update()
        }, 200);
    });

    //BLOCOS ALTURA
    $('.matcBlock').matchHeight({ remove: false });
    var mq = window.matchMedia("(max-screen: 800px)");
    if (mq.matches) {
        $('.matcBlock').matchHeight({ remove: true });
    }
});
